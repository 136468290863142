// AudioModal.js
import React from "react";

const AudioModal = ({ open, onClose, audioSrc, loading }) => {
  return (
    // Using Tailwind classes for styling
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-11/12 max-w-md mx-auto shadow-lg">
        <div className="modal-content">
          {loading ? (
            <p className="text-center text-gray-600 dark:text-gray-300">Loading...</p>
          ) : audioSrc ? (
            <audio controls autoPlay className="w-full mt-4">
              <source src={audioSrc} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            <p className="text-center text-red-600">Audio not available</p>
          )}
        </div>
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AudioModal;

