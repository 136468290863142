import { formatCurrentDate } from "utils/date";
import redmic from '../../assets/redmic.png';
import useModalStore from "zustand/zustandstore";
import BasicFilter from "./filters/basicfilter";

const Basicnav = () => {
  const mydata = useModalStore();
  const {setModalValue}=useModalStore()
  return (
    <>
      <div className="flex flex-col md:flex-row w-full items-center justify-between px-4 py-2">
        {/* Current Date */}
        <div className="text-base text-lg md:text-[20px] [font-family:'Poppins-Medium',Helvetica] font-normal text-black tracking-[0] leading-[normal] whitespace-nowrap mb-2 md:mb-0">
          {formatCurrentDate()}
        </div>

     

        {/* Start Recording Section */}
        {/* {!mydata?.isrecordingmodal ? (
          <div className="flex items-center gap-3">
            <div
              className="select-none text-sm md:text-base font-semibold"
              style={{ fontStyle: 'normal', color: "#575860" }}
            >
              Start Recording
            </div>
            <div
             onClick={() => {
setModalValue({isrecordingmodal:true})
             }
              } 
                className="relative flex cursor-pointer items-center justify-center">
              <img
                src={redmic}
                className="w-[40px] h-[40px] md:w-[50px] md:h-[50px]"
                alt="Mic"
              />
            </div>
          </div>
        ) : <></>} */}
      </div>
    </>
  );
};

export default Basicnav;
