import React, { useState } from "react";
import { Backdrop, Card, CardContent, CircularProgress } from "@mui/material";
import blinkscribelogo from "../../../assets/blinkscribe 4.svg";
import Select from "react-select";
import Flag from "react-world-flags";
import { useNavigate } from "react-router-dom";
import api from "../../../api/api";
import arrowleft from '../../../assets/arrowleft.png'
const countries = [
  { code: "US", prefix: "+1", label: "United States", value: "US", minLength: 10 },
  { code: "IN", prefix: "+91", label: "India", value: "IN", minLength: 10 },
  { code: "GB", prefix: "+44", label: "United Kingdom", value: "GB", minLength: 10 },
  { code: "DE", prefix: "+49", label: "Germany", value: "DE", minLength: 11 },
  { code: "FR", prefix: "+33", label: "France", value: "FR", minLength: 9 },
];

const OtpLoginScreen = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.value === "US")
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
const[isLoading,setIsLoading]=useState(false)
  // Handle phone number input change
  const handlePhoneNumberChange = (e) => {
    if(error){
      setError('')
    }
    const value = e.target.value;
    const onlyNumbers = value.replace(/\D/g, ""); // Replace non-numeric characters
    setPhoneNumber(onlyNumbers);
  };

  // Handle form submission
  const handleSubmit = async() => {
    if (phoneNumber.length < selectedCountry.minLength) {
      setError(`Phone number should be at least ${selectedCountry.minLength} digits long.`);
    } else {
      setError("");
try {
  setIsLoading(true)
  const fullPhoneNumber = `${selectedCountry.prefix}${phoneNumber}`;
  const response= await api.post('/v1/auth/otp/checkphone',{
    "mobilephonenumber": phoneNumber,
    "country_code" :selectedCountry.prefix
  })

  if(response.status=='200'){
    navigate("/verify", { state: { phoneNumber: phoneNumber, country_code :selectedCountry.prefix,orignfrom:"otp-login" } });
  }else if(response.status==201){
      setError('Mobile-phone is not registered')
  }
  
} catch (error) {
  console.log(error)
}finally{
  setIsLoading(false)
}
 
    }
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "8px",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  const formatOptionLabel = ({ code, label }) => (
    <div className="flex items-center">
      <Flag
        code={code}
        className="mr-2"
        style={{ width: "20px", height: "15px" }}
      />
      <span>{label}</span>
    </div>
  );

  return (<>
    <div className="flex justify-around items-center min-h-screen h-auto p-0 md:p-6 sm:p-6">
      <Card className="w-full bg-white sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-lg md:max-w-2xl p-0 md:p-6 sm:p-6 shadow-none sm:shadow-lg md:shadow-lg md:bg-white sm:bg-white h-screen sm:h-[500px] md:h-[600px] sm:rounded-lg md:rounded-lg">
        <CardContent className="h-full">
        <div className="flex items-start ">
            <img
              onClick={() => {
                navigate("/auth");
              
              }}
              src={arrowleft}
              alt="Down Arrow"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center justify-around h-full">
          
            <div className="flex justify-center mt-10 md:mt-0 sm:mt-0">
              <img
                src={blinkscribelogo}
                alt="BlinkScribe Logo"
                className="h-24 sm:h-16 md:h-16 w-30 sm:w-auto md:w-auto"
              />
            </div>
            {/* Heading */}
            <div>
              <div className="[font-family:'Inter-SemiBold',Helvetica] font-semibold text-[#1c2a3a] text-2xl md:text-xl sm:text-xl tracking-[0] leading-9 whitespace-nowrap">
                Hi, Welcome Back!
              </div>
              <div className="[font-family:'Inter-Regular',Helvetica] font-normal text-gray-500 text-[15px] tracking-[0] leading-[22.5px] whitespace-nowrap">
                Hope you’re doing fine.
              </div>
            </div>

            <div
              style={{ border: "1px solid #dcdcdc" }}
              className="flex flex-row w-[90%] md:w-[60%] sm:w-[60%] rounded-lg p-1 gap-2 md:h-auto "
            >
              <div className="md:w-[80px] w-[80px] h-[] ">
                <Select
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  options={countries}
                  styles={customStyles}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
              <div className="flex-1">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Enter Phone Number"
                  className="w-full h-[42px] md:h-[32px] bg-transparent p-3 rounded-lg outline-none text-gray-800 text-sm font-normal"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
            </div>

            {/* Error Message */}
            {error && (
              <div className="text-red-500 text-sm mt-2">{error}</div>
            )}

            {/* Buttons */}
            <div className="flex flex-col items-center space-y-4 w-full mb-24 md:mb-0 sm:mb-0">
              <button
                className="flex w-[90%] md:w-[60%] sm:w-[60%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
                onClick={handleSubmit}
              >
                <div className="font-semibold text-base">Generate One Time Password</div>
              </button>
              <button
                className="flex w-[90%] md:w-[60%] sm:w-[60%] h-15 sm:h-10 md:h-10 items-center justify-center gap-2.5 px-6 py-4 rounded-full bg-black text-white transition duration-300 ease-in-out"
                onClick={() => navigate('/login')}
              >
                <div className="font-semibold text-base">Login with Password</div>
              </button>
            </div>

            {/* Footer */}
            <div>
              <div
               onClick={()=>{navigate('/forgotpassword')}}
                className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#1c2a3a] text-[15px] text-center tracking-[0] leading-[22.5px] mb-8 sm:mb-0 md:mb-0 cursor-pointer"
              >
                Forgot Password?
              </div>
            
            </div>
            <p className="[font-family:'Inter-Regular',Helvetica] font-normal text-transparent text-[15px] text-center tracking-[0] leading-[22.5px]">
                <span className="text-gray-500">Don’t have an account yet?</span>
                <span className="text-gray-500 text-sm leading-[21px]">&nbsp;</span>
                <span
                  onClick={() => navigate('/sign-up')}
                  className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#1c64f2] text-sm leading-[21px] cursor-pointer"
                >
                  Sign up
                </span>
              </p>
            <div>
              {/* <div
                className="font-semibold text-base text-center cursor-pointer"
                style={{ color: "#0F172A", fontSize: "15px", fontWeight: "500", lineHeight: "150%" }}
              >
                Need Help?
              </div> */}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
       <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
       <CircularProgress color="inherit" />
     </Backdrop>
    </>
  );
};

export default OtpLoginScreen;
