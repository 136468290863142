import { formatCurrentDate } from "utils/date";
import checkmark from '../../assets/checkmark.png';
import editrecord from '../../assets/editrecord.png';
import pdf from '../../assets/pdf.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { formatTimestampToDDMMDtYYYY } from "utils/date";
import Ring from "pages/landing/ring";
import summarizedpng from '../../assets/summarized.png'
import { useNavigate } from "react-router-dom";
import BasicPdfRender from "basic/basicutils/basicpdfrender";
import { useState } from "react";
import api from "api/api";
import ClearIcon from '@mui/icons-material/Clear';
import { Backdrop, Chip, CircularProgress, Stack } from "@mui/material";
import useModalStore from "zustand/zustandstore";
import AudioModal from "basic/modals/audiomodal";
import { toZonedTime } from "date-fns-tz";
import { useSelector } from "react-redux";
import { decodeTimeToISO } from "utils/date";
import { clearPersistedData } from "../../redux/store";
const storedDate = (x) => {
  let normaldate = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const dateFilter = x?.filter_array?.date_filter;

  if (
    !dateFilter ||
    !dateFilter.start_date ||
    !dateFilter.end_date 

  ) {
    return normaldate;
  } else {
    let changed = [
      {
        startDate: new Date(dateFilter.start_date),
        endDate: new Date(dateFilter.end_date),
        key: "selection",
      },
    ];
    return changed;
  }
};
const formatChipDate = (date) => {
  // Check if the input is a valid Date
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return false; // Return false for invalid dates
  }

  // Convert the date to the desired timezone
  const timezone = 'Asia/Kolkata'; // Adjust the timezone as needed
  const zonedDate = toZonedTime(date, timezone);
  const today = toZonedTime(new Date(), timezone); // Get today's date in the same timezone

  // Normalize both dates to remove time components
  const isSameDay = zonedDate.getFullYear() === today.getFullYear() &&
    zonedDate.getMonth() === today.getMonth() &&
    zonedDate.getDate() === today.getDate();

  if (isSameDay) {
    return false; // Return false if the date is today
  }

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayOfMonth = zonedDate.getDate();
  const month = months[zonedDate.getMonth()];
  const year = zonedDate.getFullYear();

  // Format the date as "2 Sep, 2024"
  return `${dayOfMonth} ${month.slice(0, 3)}, ${year}`; // Shortened month name
};
const BasicTable = ({data,handletimeStamp}) => {
const navigate=useNavigate()
const [ispdfshow,setIspdfShow]=useState(false)
const [loading,setLoading]=useState(false)
const[mycard,setMyCard]=useState('')
const savedload = useSelector((state) => state.data.savedload);
const {setModalValue} = useModalStore();
const [pdffile,setPdffile]=useState()
const [audioSrc, setAudioSrc] = useState(null);
const [isModalOpen, setIsModalOpen] = useState(false);
const handleDelete = async (id) => {
  try {
    setLoading(true);
    const response = await api.delete('/v1/recording/delete', {
      data: {
        rowid: id, // Sending the payload with rowid
      },
    });
    setModalValue({trigger:true})
  } catch (error) {
    console.error('Error deleting record:', error);
  } finally {
    setLoading(false);
  }
};

const handleedit=(card)=>{
  navigate(`/basic-archive-edit/${card.id}/${card.patient_name}`)
}

const handlepdf = async (value,card) => {
  try {
     // Trigger loading spinner
     setLoading(true); 
     const uniqueParam = new Date().getTime(); // or use a random number: Math.random() 
   // Fetch the PDF from the server with a unique parameter to prevent caching
   const response = await api.get(`/v1/basic/documents/PDF/${value}?_=${uniqueParam}`, {
     responseType: "blob", // Ensure response type is 'blob' for binary data
   });
      const pdfBlob = new Blob([response], { type: "application/pdf" });
 
     // Generate a URL for the Blob
     const pdfUrl = URL.createObjectURL(pdfBlob);
     setMyCard(card)
     setPdffile(pdfBlob)
     // Open the generated URL in a new tab
     //window.open(pdfUrl, "_blank");
 
   } catch (error) {
     console.error("Error fetching the PDF:", error);
   } finally {
     // Turn off loading spinner
     setLoading(false);
     setIspdfShow(true)
   }
 };
 const handleplay=async(audio_link)=>{
  try {
    setLoading(true)
    const response = await api.get(`/v1/audio/${audio_link}`, {
      responseType: "blob", // Make sure response is treated as a Blob
    });
    const fileBlob = response;
    const audioURL = URL.createObjectURL(fileBlob);
      setAudioSrc(audioURL);
      setIsModalOpen(true)
  } catch (error) {
    console.log(error)
  }finally{
setLoading(false)
  }
 }
 const handleCloseModal = () => {
  setIsModalOpen(false);
  setAudioSrc(null); // Reset audio source when modal closes
};
const handleDateChip = () => {

  handletimeStamp({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

}
const handleOnclear = () => {
  clearPersistedData();
  window.location.reload()

}
  return (
    <div className={`w-full`}>
      <div className="w-full bg-white rounded-[14px] p-4 relative">
      <div className="flex flex-wrap gap-4 mb-4 items-center relative">
     
        <Stack direction="row" spacing={1} className="flex flex-wrap">
          {formatChipDate(storedDate(savedload)[0].startDate) ? <Chip label={formatChipDate(storedDate(savedload)[0].startDate)}
             onDelete={handleDateChip}
            deleteIcon={<div style={{ color: "white" }}><ClearIcon /></div>}

            style={{
              margin: "4px", color: "#FFF", fontStyle: "normal", background: "#B4B4B4",
            }}
          /> : <></>}</Stack>

        {(formatChipDate(storedDate(savedload)[0].startDate)) ?
          <div style={{ color: "#7B7575" }} onClick={() => {
            handleOnclear()
          }}>  <ClearIcon style={{ fontSize: '33px' }} />
          </div> : <></>}       

        
       
      </div>
        <div className="w-full max-h-[50vh] md:max-h-[55vh] overflow-y-auto custom-scrollbar">
          <table className="w-full text-left border-collapse">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th className="py-2 px-4 border-b-[1px] border-[rgba(0,0,0,0.10)] [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                  Patient Name
                </th>
                <th className="py-2 px-4 border-b-[1px] border-[rgba(0,0,0,0.10)] [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                  Date
                </th>
                <th className="py-2 px-4 border-b-[1px] border-[rgba(0,0,0,0.10)] [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
                  Status
                </th>
                <th className="py-2 px-4  border-b-[1px] border-[rgba(0,0,0,0.10)] [-webkit-text-stroke:0.3px_#060606] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-sm tracking-[0.14px] leading-[normal]">
            
                </th>
                
              </tr>
            </thead>
            <tbody className="max-h-[55vh]">
  {data.length ? (
    data.map((item, index) => (
      <tr
        key={index}
        className="h-16 mb-2 cursor-pointer"
        onClick={() => {
          const timestamp = new Date().getTime();
          const profilePhotoUrl = null;
          navigate(`/basic-archive/${item.patientid}`, {
            state: { ts: timestamp, profile_photo: profilePhotoUrl },
          });
        }}
      >
        <td className="py-2 px-4 [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-base tracking-[0] leading-[normal]">
          {item?.patient_name}
        </td>
        <td className="py-2 px-4 [font-family:'Poppins-Regular',Helvetica] font-normal text-[#060606] text-base tracking-[0] leading-[normal]">
          {formatTimestampToDDMMDtYYYY(item.ts)}
        </td>
        <td className="py-2 px-4">
                 {(() => {
            if (item.transcriptionstatus === null || item.transcriptionstatus === "") {
              return "Ready";
            } else if (item.transcriptionstatus === "Submitted") {
              return <Ring value={0} />;
            } else if (item.transcriptionstatus === "Processing" && item.handling_time == null) {
              return <Ring value={0} />;
            } else if (item.transcriptionstatus === "Processing" && item.handling_time != null) {
              const handlingTimeInt = Math.round(item.handling_time);
              return <Ring value={handlingTimeInt} />;
            } else if (item.transcriptionstatus === "Summarized") {
              return <img src={summarizedpng} alt="Completed" className="w-6 h-6" />;
            } else if (item.transcriptionstatus === "APPROVED") {
              return <img src={checkmark} alt="Completed" className="w-6 h-6" />;
            } else {
              return null;
            }
          })()}
        </td>
        <td className="py-2 px-4 min-h-[8vh] flex items-center gap-4 [font-family:'Poppins-Regular',Helvetica] font-normal text-base tracking-[0] leading-[normal]">
          {item.transcriptionstatus === "Summarized"?<><img
            src={editrecord}
            alt="Edit Icon"
            className="w-6 h-6 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleedit(item)
              // Handle edit logic here
            }}
          />
          <img
            onClick={(e) => {
              e.stopPropagation();
              handlepdf(item.audiolink.split(".")[0], item);
            }}
            src={pdf}
            alt="PDF"
            className="w-6 h-6 cursor-pointer"
          />
          <FontAwesomeIcon
            icon={faCirclePlay}
            className="w-6 h-6 cursor-pointer"
            style={{ color: "red" }}
            onClick={(e) => {
              e.stopPropagation();
              handleplay(item.audiolink)
              // Handle play logic here
            }}
          />
          <FontAwesomeIcon
            icon={faTrashCan}
            className="w-6 h-6 cursor-pointer"
            style={{ color: "#7B7575" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item.id)
              // Handle delete logic here
            }}
          /></>:<></>}
        </td>
      </tr>
    ))
  ) : (
    <></>
  )}
</tbody>

          </table>
        </div>
      </div>
      <BasicPdfRender ispdfshow={ispdfshow}setIspdfShow={setIspdfShow} pdfBlob={pdffile} data={mycard}/>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={40} />
      </Backdrop>
      <AudioModal
        open={isModalOpen}
        onClose={handleCloseModal}
        audioSrc={audioSrc}
        loading={loading}
      />
    </div>
  );
};

export default BasicTable;
