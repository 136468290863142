import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { Preview } from '@mui/icons-material';
import DocPreview from './preview';

const EditTemplate = () => {
    const [editingId, setEditingId] = useState(null);
    const [historyTexts, setHistoryTexts] = useState([
        { id: 1, title: "History_of_Present_Illness", text: "" },
        { id: 2, title: "Review_of_System", text: "" },
        { id: 3, title: "Physical_Examination", text: "" },
        { id: 4, title: "Assessment_and_Plan", text: "" }
    ]);

    const handleTitleChange = (id, e) => {
        const updatedTexts = historyTexts.map((item) =>
            item.id === id ? { ...item, title: e.target.value } : item
        );
        setHistoryTexts(updatedTexts);
    };
    const handleTextChange = (id, e) => {
        const updatedTexts = historyTexts.map((item) =>
            item.id === id ? { ...item, text: e.target.value } : item
        );
        setHistoryTexts(updatedTexts);
    };

    const toggleEdit = (id) => {
        setEditingId((prev) => (prev === id ? null : id));
    };

    const handleSave = () => {
        setEditingId(null);
    };

    const handleDelete = (id) => {
        const updatedTexts = historyTexts.filter(item => item.id !== id);
        setHistoryTexts(updatedTexts);
    };

    return (
        <div className="flex items-center justify-center w-full h-screen sm:h-auto md:h-auto">
            <div className="w-[95%] md:w-[90%] min-h-[500px] h-auto md:mt-10 p-12 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040] flex flex-col sm:flex-row">
                <div className="flex-1 pr-4">
                    <div className="flex flex-col">
                        <div className="text-base font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                            Template Name
                            <span className="label required text-red-400"> *</span>
                        </div>
                        <div className="flex items-start gap-3 mt-2">
                            <input
                                id="templateName"
                                type="text"
                                className="border border-gray-300 w-full rounded-md p-2"
                                placeholder="Template name"
                            />
                            <button className="w-[40%] sm:w-[18%] md:w-[18%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg">
                                Save
                            </button>
                        </div>

                        {historyTexts.map(({ id, title, text }) => (
                            <div key={id} className="flex flex-col mt-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        {editingId === id ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={title}
                                                    onChange={(e) => handleTitleChange(id, e)}
                                                    className="border border-gray-300 rounded-md p-2 w-full"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faSave}
                                                    className="ml-2 text-green-500 cursor-pointer"
                                                    onClick={handleSave}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <div className="text-base font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
                                                    {title}
                                                    <span className="label required text-red-400"> *</span>
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className="ml-2 text-gray-500 cursor-pointer"
                                                    onClick={() => toggleEdit(id)}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="text-red-500 cursor-pointer"
                                        onClick={() => handleDelete(id)}
                                    />
                                </div>
                                <textarea
                                    value={text}
                                    onChange={(e) => handleTextChange(id, e)}
                                   // readOnly // Keep the text area read-only for displaying details
                                    className="border border-gray-300 rounded-md p-2 mt-2 h-18 shadow-[0px_1px_3px_#00000040] "
                                    placeholder="Enter details here..."
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-full sm:w-[605px] mb-2 ml-4">
                <h3 className="text-lg font-semibold mb-2 opacity-55">Preview</h3>
                    <div className="border border-gray-300 rounded-md shadow-[0px_2px_13px_#00000040]  p-4 h-full">
                <DocPreview data={historyTexts}/>               
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTemplate;
