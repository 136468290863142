import editrecord from '../../assets/editrecord.png';
import document from '../../assets/document.png';
import { useState } from 'react';
import UploadFileModal from './uploadfilemodal';
import { useNavigate } from 'react-router-dom';

const ManageTemplate = () => {
  // Array of cards with template names
  const [isuploadmodal,setIsuploadmodal]=useState(false)
  const navigate=useNavigate()
  let cards = [
    { cardname: "Template_0.pdf" },
    { cardname: "Template_1.pdf" },
    { cardname: "Template_2.pdf" },
    { cardname: "Template_3.pdf" },
    { cardname: "Template_4.pdf" },
    { cardname: "Template_5.pdf" },

  ];

  return (
    <div className="flex items-center justify-center w-full h-screen sm:h-auto md:h-auto">
      <div className="w-[95%] md:w-[628px] min-h-[50px] h-auto md:mt-10 p-8 bg-white rounded-[20px] shadow-[0px_2px_13px_#00000040]">
      <div className="mt-4 w-full flex justify-start">
      <div className="text-base ml-[10%] font-medium  [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-start">
          Add New Template
          <span className="label required text-red-400"> *</span>
        </div>

        </div>
      

        <div className="mt-4 w-full flex justify-center">
          {/* Custom File Field */}
          <div className="flex w-[80%] border border-[#27509E] rounded-md overflow-hidden" 
          onClick={()=>{setIsuploadmodal(true)}}
          >
            <input
              type="text"
              placeholder="Choose file"
              className="w-full px-2 py-1 text-md focus:outline-none"
              readOnly
            />
            <button  className="bg-[#E6E6E6] px-3 py-1 text-[#595959] text-sm rounded-sm ml-1 mr-2 my-1">
              Browse
            </button>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="mt-6 flex justify-center space-x-4">
          <button className="w-[40%] sm:w-[30%] md:w-[28%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg">
            Upload
          </button>
          <button
          onClick={()=>navigate('/edit-temp')}
          className="w-[40%] sm:w-[30%] md:w-[28%] h-10 bg-[#1C2A3A] text-white text-sm font-semibold rounded-lg">
            Edit Template
          </button>
        </div>

        {/* Manage Template Section */}
        <div className="mt-10">
          <div className="text-base text-lg font-medium [font-family:'Poppins-Medium',Helvetica] text-black tracking-[0] leading-[normal] whitespace-nowrap text-center">
            Manage Templates
          </div>
        </div>

        {/* Scrollable Cards Section */}
        <div className="mt-4 h-70 overflow-y-auto custom-scrollbar">
          {cards.map((card, index) => (
            <div
              key={index}
              className="w-[70%] mx-auto border border-[#D1D1D1] rounded-md px-4 py-2  mb-4 flex items-center justify-between"
            >
             <div className='flex items-center gap-4'> <img src={document} className="w-6 h-6" alt="document" />
              <div className="[font-family:'Poppins-Regular',Helvetica] font-normal text-start text-[#262629] text-[13px] tracking-[0] leading-[normal]">
                {card.cardname}
              </div></div>
              {index === 0 ? (
                <div className="text-xs opacity-50 ml-2">Default</div>
              ):<div className="text-xs opacity-50 ml-2"></div>}
              <img src={editrecord} className="w-6 h-6" alt="edit" />
            </div>
          ))}
        </div>
      </div>

      {isuploadmodal?<UploadFileModal setIsuploadmodal={setIsuploadmodal}/>:<></>}
    </div>
  );
};

export default ManageTemplate;
