import profilering from '../../assets/profilering.png';
import face from '../../assets/profile.png';
import CloseIcon from '@mui/icons-material/Close';
import redcircle from '../../assets/redcircle.png';
import pause from '../../assets/Pause.png';
import play from '../../assets/play.png';
import stop from '../../assets/ion_stop.png';
import { useEffect, useRef, useState } from 'react';
import DeleteModal from 'basic/modals/delete';
import useModalStore from 'zustand/zustandstore';
import SaveModal from 'basic/modals/savemodal';
import CancelIcon from '@mui/icons-material/Cancel';
import ToastModal from 'alerts/recordingscreen/toastmodal';
import DangerousIcon from '@mui/icons-material/Dangerous';
import AlertMsg from 'basic/modals/alertmsg';
import ReactiveWaves from 'audiopopup/reactivewaves';
const BasicRecordingModule=({submitAudio})=>{
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [recordingTime, setRecordingTime] = useState("00:00"); 
    const[persistRecordTime,setPersistRecordTime]=useState('')
    const[isDeletemodal,setIsDeleteModal]=useState(false)
    const[savemodal,setSaveModal]=useState(false)
    const[showsavedtime,setSavedTime]=useState(false)
    const {setModalValue}=useModalStore()
    const mydata=useModalStore()
    const[toastAlert,setToastAlert]=useState(false)
    const [toastobject,setToastObject]=useState({
        icon:DangerousIcon,
        text1:"Something Went Wrong",
        text2:""
    
      })
    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const startTimeRef = useRef(null); // For when recording started
    const elapsedTimeRef = useRef(0); // To store total elapsed time
    const animationFrameRef = useRef(null); 
    const convertToMilliseconds = (timeString) => {
        const [minutes, seconds] = timeString.split(':').map(Number);
        const totalSeconds = minutes * 60 + seconds;
        return totalSeconds * 1000; // Convert seconds to milliseconds
      };
      const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setMediaStream(stream); 
        mediaRecorderRef.current = new MediaRecorder(stream);
    
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.current.push(event.data);
          }
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });    
       
          submitAudio(audioBlob);
                
        const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
         //sessionStorage.setItem('audioBlob', base64data);
      };
      reader.readAsDataURL(audioBlob);
          audioChunks.current = [];
        };    
        mediaRecorderRef.current.start();
       
        startTimeRef.current = Date.now(); // Record start time
    
        setIsRecording(true);
        setIsPaused(false);
        updateRecordingTime(); // Start timer
            
      };
      useEffect(()=>{
        updateRecordingTime()
        },[])

        const stopRecording = () => {
            if (!isRecording) return;  
               mediaRecorderRef.current.stop();
                setIsRecording(false);
               setIsPaused(false);
                 cancelAnimationFrame(animationFrameRef.current); 
               return 
             };
             const pauseRecording = () => {
                if (!mediaRecorderRef.current) return;
            
                if (mediaRecorderRef.current.state === "recording") {
                  mediaRecorderRef.current.pause();                 
                  setIsPaused(true);
                  elapsedTimeRef.current += Date.now() - startTimeRef.current;
                   setPersistRecordTime(recordingTime)
                  setSavedTime(true)
                  // Update total elapsed time
                  cancelAnimationFrame(animationFrameRef.current); // Stop timer
                }
              };
              const resumeRecording = () => {
                if (!mediaRecorderRef.current) return;
                if (mediaRecorderRef.current.state === "paused") {
                  mediaRecorderRef.current.resume();
                  setIsPaused(false);
                  startTimeRef.current = Date.now(); // Reset start time
                  
                  setSavedTime(false)
                  updateRecordingTime(); // Resume timer
                }
              };
              const updateRecordingTime = () => {
                const now = Date.now();
                const elapsedTime = Math.floor((now - startTimeRef.current + elapsedTimeRef.current) / 1000); // Add elapsed time to current time
                const minutes = Math.floor(elapsedTime / 60);
                const seconds = elapsedTime % 60;
                setRecordingTime(
                  `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
                );
            
                animationFrameRef.current = requestAnimationFrame(updateRecordingTime); // Continue updating timer
              };
              useEffect(() => {
                if (!isRecording) {
                  startRecording();
                }
            
                return () => {
                  cancelAnimationFrame(animationFrameRef.current); // Clean up timer on component unmount
                };
              }, [isRecording]);

              const handledelete=()=>{
                setSaveModal(false)
               setIsDeleteModal(false)
               setRecordingTime('00:00')
               setPersistRecordTime('00:00')
               setToastObject({
                icon: CancelIcon,
                text1: "Your Recording is Successfully Cancelled!",
                text2: "",
              });
              setToastAlert(true);
             
              }
            const handlesave=()=>{
              setSaveModal(false)
              stopRecording()
            }

    return(<>
    <div className="absolute left-0 right-0 top-[70px] mt-4 w-full flex justify-center md:top-0 md:left-[30%] md:right-0 md:mt-4 lg:w-[70%] xl:w-[50%]" style={{ zIndex: 99999999 }}>
            <div style={{ borderRadius: "4px", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }} className="bg-white rounded-[15px] p-4 h-[53px] flex items-center justify-between w-full">
                {/* Profile icon section */}
                <div className='flex items-center gap-4'>
                    <div className="relative">
                        <img src={profilering} alt="Profile Ring" className="w-[44px] h-[45px]" />
                        <img src={face} alt="Face" className="absolute inset-0 w-[31px] h-[43px] m-auto rounded-full" />
                    </div>
                    <div className='font-semibold text-black text-sm'>
                    {mydata.patName||''}
                    </div>
                </div>

                {/* Audio section */}
                <div className='flex gap-4 items-center'>
                <div
              className="relative cursor-pointer"
              onClick={() => {
                if (isPaused) {
                  resumeRecording(); // Call resumeRecording when paused
                  // Update modalValue to false
                } else {
                  pauseRecording(); // Call pauseRecording when not paused
                  // Update modalValue to true
                }
              }}
              
            >
              <img
                src={redcircle}
                alt="Red Circle"
                className="w-[35px] h-[35px] cursor-pointer"
              />
              <img
                src={!isPaused ? pause : play}
                alt="Pause/Play"
                className="absolute inset-0 m-auto w-[20px] h-[20px]"
              />
            </div>

                    <div className='flex flex-col items-center h-[90%] justify-between '>
                      
                      <div className='h-4 overflow-hidden'> 
                        <ReactiveWaves isPaused={!isPaused}  mediaStream={mediaStream}/>
                        </div>
                        <div
                        style={{fontStyle:"normal",fontFamily:'inter'}}
                        className='bg-#1C2A3A h-3 '>
                            {recordingTime.length !== 5 ? "00:00" : (showsavedtime ? persistRecordTime : recordingTime)}
                        
                        </div>
                    </div>

                    <div className="relative cursor-pointer" 
                    onClick={()=>{
                      if(isDeletemodal){
                        return
                      }                     
                      pauseRecording()
                      setSaveModal(true)
                    
                    }}
                    >
                        <img src={redcircle} alt="Red Circle" className="w-[35px] h-[35px] cursor-pointer" />
                        <img src={stop} alt="Stop" className="absolute inset-0 m-auto w-[20px] h-[20px]" />
                    </div>
                </div>

                <div onClick={()=> {
            if(savemodal){
              return
            }

                  pauseRecording();
                  setIsDeleteModal(true)                                 
                  }} >
                    <CloseIcon style={{ color: "#7B7575" }} />
                </div>
            </div>
        </div>
     <DeleteModal isModalOpen={isDeletemodal} setIsDeleteModal={setIsDeleteModal} handledelete={handledelete}/>
    <SaveModal savemodal={savemodal} 
    handlesave={handlesave}
    setModalOpen={setSaveModal} handledelete={handledelete}/>
  <AlertMsg toastobject={toastobject} isModalOpen={toastAlert} setModalOpen={setToastAlert}/>   
    </>
   
  )
}
export default BasicRecordingModule;