import RecordingWrap from "pages/recording/recordingwrap"
import BlinkscribeHome from "../pages/landing/BlinkScribeHome"

import LockPage from "pages/auth/screens/lock"
import UsernamePasswordLogin from "pages/auth/screens/logincred"
import OtpLoginScreen from "pages/auth/screens/login"
import BlinkScribeSignup from "pages/auth/screens/signup"
import Auth from "pages/auth/screens/auth"
// import Getotp from "pages/auth/screens/getotp"
import ForgotPassword from "pages/auth/screens/forgotpassword"
import Archive from "pages/recording/Archive"
import Editscreen from "pages/recording/Editscreen"
import Verify from "pages/auth/screens/Verify"
import UpdatePassword from "pages/auth/screens/updatepassword"
import ManageProf from "pages/profile/manageprof"
import Test from "test"
import BasicDashboard from "basic/dashboard/basicdashboard"
import TemplateBase from "pages/templatecreation/templatebase"
import EditTemplate from "pages/templatecreation/edittemplate"
import BasicArchive from "basic/archeive/basicarchive"
import BasicEditscreen from "basic/archeive/basiceditscreen"

export const routes=[{
    path:'/landing',
    component:BlinkscribeHome,
    showHeader:true,
    allowedTypes: ["PRO"], 
},{
    path:'/record/:id/:patientid',
    component:RecordingWrap,
    showHeader:true,
    allowedTypes: ["PRO"], 
},
{
    path:'/archive/:id',
    component:Archive,
    showHeader:true,
    allowedTypes: ["PRO"], 
},
{
    path:'/archive-edit/:id/:name',
    component:Editscreen,
    showHeader:true,
    allowedTypes: ["PRO"],
},
{
    path:'/manage-profile',
    component:ManageProf,
    showHeader:true,
    allowedTypes: ["PRO","BASIC"], 
},{
    path:'/auth',
    component:Auth,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"], 
},{
    path:'/test',
    component:Test,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"], 
},
{
    path:'/auth1',
    component:OtpLoginScreen,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"], 
},
{
    path:'/login',
    component:UsernamePasswordLogin,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"], 
},
{
    path:'/verify',
    component:Verify,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"], 
},
{
    path:'/forgotpassword',
    component:ForgotPassword,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"], 
},
{
    path:'/updatepassword',
    component:UpdatePassword,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"],  
},
{
    path:'/sign-up',
    component:BlinkScribeSignup,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"],  
}
,{
    path:'/lock',
    component:LockPage,
    showHeader:false,
    allowedTypes: ["PRO","BASIC"],  
},
{
    path:'/temp',
    component:TemplateBase,
    showHeader:true,
    allowedTypes: ["PRO"], 
},
{
    path:'/edit-temp',
    component:EditTemplate,
    showHeader:true,
    allowedTypes: ["PRO"], 
}
//basic routes
,{
    path:'/basic',
    component:BasicDashboard,
    showHeader:true,
    allowedTypes: ["BASIC"], 
},
{
    path:'/basic-archive/:id',
    component:BasicArchive,
    showHeader:true,
    allowedTypes: ["BASIC"], 
},
{
    path:'/basic-archive-edit/:id/:name',
    component:BasicEditscreen,
    showHeader:true,
    allowedTypes: ["BASIC"],
},

]